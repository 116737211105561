<template>
  <div class="hello">
    <p>
    Thank you so much for celebrating with us. It was such a special day and it meant so much to have everyone together.
    </p>
    <p>
    
    </p>

  </div>
</template>
